import React from 'react'
import { graphql } from 'gatsby'

import LayoutCart from '../components/LayoutCart'

// Export Template for use in CMS preview
export const DefaultCartTemplate = ({ title, subtitle, featuredImage, body }) => (
  <main className="Home">
    <img style={{
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto'
    }} src="/images/menulargo.jpg" alt="default - cart " />
  </main>
)

// Export Default HomePage for front-end
const DefaultCart = ({ data: { page } }) => (
  <LayoutCart meta={page.frontmatter.meta || false}>
    <DefaultCartTemplate {...page} {...page.frontmatter} body={page.html} />
  </LayoutCart>
)

export default DefaultCart

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query DefaultCart($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
      }
    }
  }
`
